@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='floating'] {
    background: var(--tui-background-elevation-2);
    color: var(--tui-text-primary);
    box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);

    .appearance-hover({
        box-shadow: 0 0.875rem 2.625rem rgba(0, 0, 0, 0.14);
    });

    .appearance-active({
        background: var(--tui-background-base-alt);
        box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
    });
}
