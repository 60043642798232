@height-cell-large: 5rem;

.tui-table {
    inline-size: 100%;

    &_layout_fixed {
        table-layout: fixed;
    }

    &__tr {
        .transition(background);

        outline: none;

        &:not(&_hover_disabled):hover {
            background: var(--tui-background-base-alt);
        }

        &:not(&_border_none) {
            border-block-end: 1px solid var(--tui-border-normal);
        }

        &_border_top {
            border-block-start: 1px solid var(--tui-border-normal);
        }

        &_cursor_pointer {
            cursor: pointer;
        }
    }

    &__th {
        .text-overflow();

        font: var(--tui-font-text-s);
        padding: 0.5rem 1rem;
        vertical-align: middle;
        block-size: 2.5rem;
        color: var(--tui-text-secondary);
        background: inherit;

        &_font-size_l,
        .tui-table_font-size_l & {
            font: var(--tui-font-text-m);
        }
    }

    &__td {
        font: var(--tui-font-text-m);
        padding: 1rem 1rem 0.875rem;
        vertical-align: top;
        overflow-wrap: break-word;
        block-size: 3.4375rem;
        color: var(--tui-text-primary);
        background: inherit;

        &_size_l,
        .tui-table_size_l & {
            block-size: @height-cell-large - 0.0625rem;
        }

        &_font-size_l,
        .tui-table_font-size_l & {
            font: var(--tui-font-text-l);
        }

        &_font-size_s,
        .tui-table_font-size_s & {
            font: var(--tui-font-text-s);
        }

        &_align_center {
            vertical-align: middle;
        }
    }

    &__td,
    &__th {
        box-sizing: border-box;
        text-align: start;

        .tui-table_size_l &::before {
            inline-size: 1rem;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }

        &_first::before,
        &_last::before {
            content: '';
            position: absolute;
            top: 0;
            inline-size: 0.75rem;
            block-size: 100%;
            background: inherit;
            border-top-left-radius: 0.75rem;
            border-bottom-left-radius: 0.75rem;
        }

        &_first {
            position: relative;
            padding-left: 0;
            overflow: visible;

            &::before {
                right: 100%;
            }
        }

        &_last {
            position: relative;
            padding-right: 0;
            overflow: visible;

            &::before {
                left: 100%;
                transform: scaleX(-1);
            }
        }

        &_text_center {
            text-align: center;
        }

        &_text_right {
            text-align: end;
        }

        &_text_overflow {
            .text-overflow();
        }
    }

    &__subtext {
        font: var(--tui-font-text-s);
        margin: 0.25rem 0 0;
        color: var(--tui-text-tertiary);
    }

    &__sort {
        .button-clear();
        .transition(color);

        position: relative;
        display: inline-flex;
        align-items: center;
        outline: none;
        cursor: pointer;

        &:hover {
            color: var(--tui-text-primary);
        }

        &_active {
            color: var(--tui-text-action);

            &:hover {
                color: var(--tui-text-action-hover);
            }
        }
    }

    &__sort-icon {
        .center-top();

        left: 100%;
        margin-left: 0.125rem;
        inline-size: 1rem;
        block-size: 1rem;
        flex-shrink: 0;
        opacity: 0;

        .tui-table__sort_active &,
        .tui-table__sort:hover & {
            .transition(transform);

            opacity: 1;
        }

        .tui-table__sort_active.tui-table__sort_up & {
            transform: rotate(180deg) translateY(50%);
        }
    }
}
