@import 'browsers.less';

.interactive(@ruleset) {
    // TODO remove after dropping Safari 13 support
    &:matches(a, button, select, textarea, input, label):not(:disabled) {
        @ruleset();
    }

    &:is(a, button, select, textarea, input, label):not(:disabled) {
        @ruleset();
    }
}

// centering with translate
.center-left() {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.center-top() {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.center-all() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//.fullsize
// @deprecated TODO remove in 5.0 in favor of inset: 0
.fullsize(@position: absolute, @mode: height) {
    position: @position;
    top: 0;
    left: 0;

    & when (@mode = height) {
        inline-size: 100%;
        block-size: 100%;
    }

    & when (@mode = inset) {
        bottom: 0;
        right: 0;
    }
}

.button-clear() {
    appearance: none;
    padding: 0;
    border: 0;
    background: none;
    font: inherit;
    line-height: inherit;
    text-decoration: none;
}

.button-base() {
    .button-clear();

    position: relative;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    max-inline-size: 100%;
    // StackBlitz changes "0rem" to "0" breaking calc
    gap: calc(var(--t-gap, ~'0rem') - 2 * var(--t-margin, 0rem));

    > img,
    > tui-svg,
    > tui-icon,
    > tui-avatar,
    > tui-badge,
    > [tuiBadge],
    > [tuiRadio],
    > [tuiSwitch],
    > [tuiCheckbox],
    &[tuiIcons]::before,
    &[tuiIcons]::after {
        margin: var(--t-margin);
    }
}

// transition
.transition(@param: all, @speed: var(--tui-duration, 300ms)) {
    transition-property: @param;
    transition-duration: @speed;
    transition-timing-function: ease-in-out;
}

// gradient
.gradient(@start-color, @end-color, @angle: 45deg) {
    background-image: linear-gradient(@angle, @start-color 0%, @end-color 100%);
}

// typical properties for text overflow with ellipsis
.text-overflow(@type: nowrap) {
    white-space: @type;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollbar-hidden() {
    /* stylelint-disable*/
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* stylelint-enable*/

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

// hide an element visually without hiding it from screen readers
.sr-only() {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    block-size: 1px;
    inline-size: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
}

// prevent scrolling to focused element on IOS
.tui-prevent-ios-scroll() {
    .ios-only({
        &:focus {
            animation: tuiPreventIOSScroll 0.001s;
        }
    });

    @keyframes tuiPreventIOSScroll {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

.text-truncate() {
    .text-overflow();

    flex: 1;
    min-inline-size: 0;
    max-inline-size: max-content;
}
