@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='textfield'] {
    .transition(~'box-shadow, background-color, outline-color, border-color');

    --t-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1);

    background-color: var(--tui-background-base);
    color: var(--tui-text-primary);
    box-shadow: var(--t-shadow);
    outline: 1px solid var(--tui-border-normal);
    outline-offset: -1px;
    border: none;

    // TODO: Remove tuiWrapper hack in v5
    &:not([tuiWrapper])::before,
    &:not([tuiWrapper])::after {
        .transition(~'color, transform');

        color: var(--tui-text-tertiary);
    }

    .appearance-hover({
        --t-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.16);

        &:not([tuiWrapper]):before,
        &:not([tuiWrapper]):after {
            color: var(--tui-text-secondary);
        }
    });

    .appearance-active({
        &:not([tuiWrapper]):before,
        &:not([tuiWrapper]):after {
            color: var(--tui-text-primary);
        }
    });

    .appearance-focus({
        box-shadow: none;
        outline: 0.125rem solid var(--tui-background-accent-1);
        outline-offset: -0.125rem;
    });

    .appearance-disabled({
        box-shadow: none;
    });

    &[data-mode~='invalid'][data-mode~='invalid'],
    &:invalid:not([data-mode]) {
        outline-color: var(--tui-status-negative);
    }

    &[data-mode~='readonly'],
    input&:read-only:not([data-mode]) {
        background: transparent !important;
        box-shadow: none !important;
        outline-color: var(--tui-border-normal) !important;
    }

    &[chrome-autofilled], /* Chrome on IOS */
    &:-webkit-autofill {
        -webkit-text-fill-color: var(--tui-text-primary) !important;
        caret-color: var(--tui-text-primary) !important;
        box-shadow: 0 0 0 100rem var(--tui-service-autofill-background) inset !important;
        transition: background-color 600000s 0s;
    }
}

[tuiTheme='dark'] [tuiAppearance][data-appearance='textfield'],
[tuiTheme='dark'][tuiAppearance][data-appearance='textfield'] {
    background-color: var(--tui-background-neutral-1);
    color-scheme: dark;

    .appearance-hover({
        background-color: var(--tui-background-neutral-1-hover);
    });

    .appearance-focus({
        background-color: transparent !important;
    });
}
