@import 'mixins.less';

.appearance-hover(@content) {
    .interactive({
        @media @tui-mouse {
            &:hover:not(:disabled):not([data-state]) {
                @content();
            }
        }
    });

    &[data-state='hover'] {
        @content();
    }

    /* @deprecated TODO remove in v5 */
    @media @tui-mouse {
        &[tuiWrapper]:hover:not(._no-hover),
        &[tuiWrapper][data-state='hover'] {
            @content();
        }
    }
}

.appearance-active(@content) {
    .interactive({
        &:active:not(:disabled):not([data-state]) {
            @content();
        }
    });

    &[data-state='active'] {
        @content();
    }

    /* @deprecated TODO remove in v5 */
    &[tuiWrapper]:active:not(._no-active),
    &[tuiWrapper][data-state='active'],
    &[tuiWrapper][data-state='active']:hover {
        @content();
    }
}

.appearance-disabled(@content) {
    &:disabled:not([data-state]),
    &[data-state='disabled'] {
        @content();
    }

    /* @deprecated TODO remove in v5 */
    &[tuiWrapper]:disabled:not([data-state]),
    &[tuiWrapper][data-state='disabled'] {
        @content();
    }
}

.appearance-focus(@content) {
    &:focus-visible:not([data-focus='false']) {
        @content();
    }

    &[data-focus='true'] {
        @content();
    }

    /* @deprecated TODO remove in v5 */
    &[tuiWrapper]:not(._focused):has(:focus-visible),
    &[tuiWrapper]._focused {
        @content();
    }
}
