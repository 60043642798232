@import '@taiga-ui/core/styles/taiga-ui-local.less';

/* TODO @deprecated remove in v5 */
[tuiAppearance][data-appearance='opposite'] {
    --tui-border-focus: #979797;

    background: var(--tui-background-accent-opposite);
    color: var(--tui-background-base);

    .appearance-hover({
        background: var(--tui-background-accent-opposite-hover);
    });

    .appearance-active({
        background: var(--tui-background-accent-opposite-pressed);
    });
}
