@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

app-root, tui-root {
  height: 100vh;
}

.t-root-content {
  margin: 0;
  background: var(--tui-background-base-alt) !important;
  color: var(--tui-text-primary) !important;
  font: var(--tui-font-text-s) !important;
}

button {
  font-weight: 500 !important;
}

.container {
  margin: 0 auto;
  padding: 30px 15px 0;
}

hr {
  margin: 2rem 0;
  height: 1px;
}

p {
  font: var(--tui-font-text-m);
  line-height: 24px;
}

.styled-link {
  font-weight: 500 !important;
  color: var(--tui-background-accent-1) !important;
  display: block;
}

[tuiWrapper][data-appearance='secondary'] {
  background: var(--tui-background-base) !important;
  color: var(--tui-text-secondary) !important;
}

tui-root {
  tui-scroll-controls {
    .t-bar_vertical {
      top: 4rem !important;
    }
  }
}

.tui-island {
  padding: 48px;
  box-sizing: border-box;

  &.with-bg {
    background-size: cover;
    background-position: center;
  }
}

//table {
//  overflow: hidden;
//  table-layout: auto;
//
//
//  th, td {
//    border: none !important;
//    border-bottom: 1px solid !important;
//    border-color: var(--tui-background-neutral-1-hover) !important;
//    padding: var(--tui-padding-xl) !important;
//    font-size: 14px !important;
//
//    &:first-child {
//      padding-left: 0 !important;
//    }
//
//    &:last-child {
//      padding-right: 0 !important;
//      text-align: right;
//    }
//  }
//
//  th {
//    font-weight: 500;
//    color: var(--tui-text-primary) !important;
//  }
//
//  th, td {
//    background: transparent !important;
//  }
//
//  tfoot {
//    td {
//      border: none !important;
//    }
//  }
//}

[tuiTh],
[tuiTd] {
  border-inline-start: none;
  border-inline-end: none;

  border: none !important;
  border-bottom: 1px solid !important;
  border-color: var(--tui-background-neutral-1-hover) !important;
}

[tuiTh] {
  font-weight: 500;
  color: var(--tui-text-primary) !important;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.icon-button {
  transition: opacity .3s;
  cursor: pointer;
  padding: 4px;

  &:hover {
    opacity: .75;
  }
}

.table-action-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

tui-dialog[data-appearance='custom-modal'] {
  width: 63.5rem !important;

  .t-content {
    padding: 0;
  }
}

.separate-line {
  width: 100%;
  text-align: center;
  margin-bottom: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after, &:before {
    content: '';
    height: 1px;
    width: 100%;
    background: var(--tui-background-neutral-1-hover);
  }

  span {
    padding: 0 15px;
  }
}

aside[tuiNavigationAside] {
  background: var(--tui-background-base) !important;
  box-shadow: inset -1px 0 0 0 var(--tui-border-hover);
}

main[tuiNavigationMain] {
  background: var(--tui-background-base-alt) !important;
  color: var(--tui-text-primary) !important;
  font: var(--tui-font-text-s) !important;
}

tui-data-list [tuiOption].active, {
  background-color: var(--tui-background-neutral-1);
}
