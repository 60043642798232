.tui-list {
    font: var(--tui-font-text-m);
    line-height: 1.5rem;
    list-style-type: none;
    color: var(--tui-text-primary);

    &_large {
        font: var(--tui-font-text-l);
        line-height: 1.75rem;
    }

    &_small,
    &_extra-small {
        font: var(--tui-font-text-s);
        line-height: 1.25rem;
    }

    &_nested {
        margin: 0.75rem 0 0.75rem 1.25rem;
        color: var(--tui-text-secondary);

        .tui-list_large & {
            margin-left: 0.75rem;
        }

        .tui-list_small & {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }

    &_ordered {
        counter-reset: counter;
    }

    &__item {
        position: relative;
        padding-left: 1.5rem;
        overflow-wrap: break-word;
        margin-top: 0.75rem;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0.5rem;
            inline-size: 0.5rem;
            block-size: 0.5rem;
            border-radius: 100%;
            background-color: var(--tui-background-accent-1);

            .tui-list_large > & {
                top: 0.625rem;
            }

            .tui-list_small > & {
                top: 0.325rem;
            }
        }

        .tui-list_extra-small > & {
            margin-top: 0.5rem;
        }

        .tui-list_linear & {
            padding-left: 1.75rem;

            &::before {
                content: '\2014';
                left: 0;
                top: auto;
                inline-size: auto;
                block-size: auto;
                background-color: transparent;
            }
        }

        .tui-list_ordered & {
            padding-left: 1.25rem;

            &::before {
                content: counter(counter) '.';
                left: 0;
                top: auto;
                counter-increment: counter;
                inline-size: auto;
                block-size: auto;
                color: var(--tui-text-tertiary);
                background-color: transparent;
            }
        }

        .tui-list_triangle > & {
            padding-left: 1.75rem;

            &::before {
                content: '\25E4'; // represent symbol '◤'
                left: 0;
                top: 1px;
                inline-size: auto;
                block-size: auto;
                background-color: transparent;
                color: var(--tui-text-tertiary);
                font-size: 0.75rem;
            }
        }
    }

    &__item-title {
        font: var(--tui-font-heading-5);
        margin: 0;

        .tui-list_small & {
            line-height: 1.25rem;
            font-weight: bold;
        }
    }

    &__description {
        margin: 0.5rem 0 0;
        color: var(--tui-text-secondary);

        .tui-list_small & {
            margin-top: 0.25rem;
        }

        .tui-list_large & {
            line-height: 1.75rem;
            margin-top: 0.75rem;
        }
    }
}
