@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='primary'] {
    --t-bg: var(--tui-background-accent-1);

    background: var(--t-bg);
    color: var(--tui-text-primary-on-accent-1);

    &:invalid:not([data-mode]),
    &[data-mode~='invalid'] {
        background: var(--tui-status-negative);
    }

    .appearance-hover({
        --t-bg: var(--tui-background-accent-1-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-background-accent-1-pressed);
    });
}

[tuiAppearance][data-appearance='primary-destructive'] {
    color: #fff;
    background: var(--tui-status-negative);

    .appearance-hover({
        filter: saturate(1) brightness(1.3);
    });

    .appearance-active({
        filter: saturate(0.8) brightness(1);
    });
}

[tuiAppearance][data-appearance='primary-grayscale'] {
    --tui-border-focus: #979797;

    background: var(--tui-background-accent-opposite);
    color: var(--tui-background-base);

    .appearance-hover({
        background: var(--tui-background-accent-opposite-hover);
    });

    .appearance-active({
        background: var(--tui-background-accent-opposite-pressed);
    });
}
