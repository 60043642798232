/* TODO @deprecated remove in v5 */
[tuiAppearance][data-appearance='link'] {
    color: var(--tui-text-action);
    background: transparent;

    .appearance-hover({
        color: var(--tui-text-action-hover);
    });

    .appearance-active({
        color: var(--tui-text-action-hover);
    });
}

[tuiAppearance][data-appearance='action'] {
    color: var(--tui-text-action);
    background: transparent;

    .appearance-hover({
        color: var(--tui-text-action-hover);
    });

    .appearance-active({
        color: var(--tui-text-action-hover);
    });
}

[tuiAppearance][data-appearance='action-destructive'] {
    color: var(--tui-text-negative);
    background: transparent;

    .appearance-hover({
        color: var(--tui-text-negative-hover);
    });

    .appearance-active({
        color: var(--tui-text-negative-hover);
    });
}

[tuiAppearance][data-appearance='action-grayscale'] {
    color: var(--tui-text-secondary);
    background: transparent;

    .appearance-hover({
        color: var(--tui-text-primary);
    });

    .appearance-active({
        color: var(--tui-text-primary);
    });
}
